import VRadioGroup from './VRadioGroup'
import VRadio from './VRadio'

export { VRadioGroup, VRadio }

export default {
  $_vuetify_subcomponents: {
    VRadioGroup,
    VRadio
  }
}
