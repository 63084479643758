import VTimePicker from './VTimePicker'
import VTimePickerClock from './VTimePickerClock'
import VTimePickerTitle from './VTimePickerTitle'

export { VTimePicker, VTimePickerClock, VTimePickerTitle }

export default {
  $_vuetify_subcomponents: {
    VTimePicker,
    VTimePickerClock,
    VTimePickerTitle
  }
}
