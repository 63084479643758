import VWindow from './VWindow'
import VWindowItem from './VWindowItem'

export { VWindow, VWindowItem }

export default {
  $_vuetify_subcomponents: {
    VWindow,
    VWindowItem
  }
}
