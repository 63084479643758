import VCarousel from './VCarousel'
import VCarouselItem from './VCarouselItem'

export { VCarousel, VCarouselItem }

export default {
  $_vuetify_subcomponents: {
    VCarousel,
    VCarouselItem
  }
}
