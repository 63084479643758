import VItem from './VItem'
import VItemGroup from './VItemGroup'

export {
  VItem,
  VItemGroup
}

export default {
  $_vuetify_subcomponents: {
    VItem,
    VItemGroup
  }
}
