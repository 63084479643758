import VTabs from './VTabs'
import VTab from './VTab'
import VTabsItems from './VTabsItems'
import VTabItem from './VTabItem'
import VTabsSlider from './VTabsSlider'

export { VTabs, VTab, VTabItem, VTabsItems, VTabsSlider }

export default {
  $_vuetify_subcomponents: {
    VTabs,
    VTab,
    VTabsItems,
    VTabItem,
    VTabsSlider
  }
}
