import VTimeline from './VTimeline'
import VTimelineItem from './VTimelineItem'

export { VTimeline, VTimelineItem }

export default {
  $_vuetify_subcomponents: {
    VTimeline,
    VTimelineItem
  }
}
